import React, { useState } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Layout from '../components/LayoutLanding'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import '../styles/styles.scss'
import styled from 'styled-components'

const download = ({ className }) => {
  return (
    <Layout>
      <div className="wrap">
        <StaticQuery
          query={graphql`
            query {
              image: file(relativePath: { eq: "thelift.jpeg" }) {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 2500) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              book: file(relativePath: { eq: "thelift3dbook.png" }) {
                childImageSharp {
                  fixed(width: 370) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
            }
          `}
          render={data => {
            const image = data.image.childImageSharp.fluid
            const book = data.book.childImageSharp.fixed

            return (
              <BackgroundImage className={className} fluid={image}>
                <div className="download-outer-wrap">
                  <div className="img-wrap-book">
                    <Img
                      className="book-apprentice-download"
                      fixed={book}
                      alt=""
                    />
                  </div>

                  <div className="download-top-wrap">
                    <div className="download-top-text-wrap">
                      <div className="download-title">
                        <h1>Thank you for subscribing</h1>
                        <h1> Choose Your Download Below:</h1>
                      </div>
                      <p>
                        I find the best and easiest download, if you have an
                        Apple device is the ePub file. However, the one that I
                        think looks best is the Kindle mobi file, and you can
                        download for Kindles and Kindle Apps. There is also a
                        PDF file which will run on any device. I have included
                        instructions lower down the page, but if you get stuck
                        email me at richard@richardweale.com
                      </p>
                    </div>
                  </div>

                  <div className="download-text-wrap">
                    <p>
                      To download THE LIFT for Kindle &#40; mobi format &#41;
                      click the link below:
                    </p>
                    <a
                      href="https://theliftnovella.s3-us-west-2.amazonaws.com/THE-LIFT-Kindle.mobi"
                      download
                    >
                      DOWNLOAD LINK To download THE LIFT
                    </a>
                    <p>
                      To download THE LIFT for iTunes, Android, Kobo, Nook or
                      iBook on your Mac &#40; epub format &#41; click the link
                      below:
                    </p>
                    <a
                      href="https://theliftnovella.s3-us-west-2.amazonaws.com/THE-LIFT-Generic.epub"
                      download
                    >
                      DOWNLOAD LINK To download THE LIFT
                    </a>
                    {/* <p>
                      To download APPRENTICE in &#40; PDF format &#41; clink the
                      link below:
                    </p>
                    <a
                      href="https://apprenticedownload.s3-us-west-2.amazonaws.com/APPRENTICE-Print.pdf"
                      download
                    >
                      DOWNLOAD LINK To download APPRENTICE
                    </a> */}
                  </div>
                  <div className="download-text-wrap">
                    <h1>INSTRUCTIONS</h1>
                    <p>
                      If you're using an apple device, just click the download
                      link above, then select "open in Kindle" or "open in
                      iBooks."
                    </p>

                    <p>
                      &#8277;&#8277;&#8277; For iPad and iPhone, you might have
                      to tap the download link twice. &#8277;&#8277;&#8277;
                    </p>

                    <p>
                      If you're using a different device, you may need to use a
                      computer to download the file, then transfer to your
                      reading app.
                    </p>

                    <p>
                      More instructions on how to do this can be found here:
                    </p>

                    <h3>For Devices using the Kindle app:</h3>

                    <br />
                    <a href="http://www.amazon.com/gp/sendtokindle">
                      Click Here for Kindle
                    </a>

                    <h3>For Kobo:</h3>

                    <br />
                    <a href="https://www.kobo.com/help/en-US/article/3174/adding-books-to-your-kobo-ereader">
                      Click Here for Kobo Ereader
                    </a>

                    <h3>For Nook:</h3>

                    <br />
                    <a href="https://help.barnesandnoble.com/app/nook_support/list">
                      Click Here for Nook Support
                    </a>

                    <p>
                      If you have any questions, get in touch at
                      richard@richardweale.com
                    </p>

                    <h2> WHICH FILE TYPE SHOULD I CHOOSE?</h2>

                    <p>
                      KINDLE .MOBI FILE: Compatible with all Kindle ereaders and
                      most other devices using the free Kindle app.
                    </p>

                    <p>EPUB: Compatible with Nook, Kobo, and Apple devices </p>

                    {/* <p>
                      PDF FILE: A standard format, compatible with all devices.
                      &#40; pdf is a print version and links don't work &#41;
                    </p> */}
                  </div>
                </div>
              </BackgroundImage>
            )
          }}
        />
      </div>
    </Layout>
  )
}

const StyledBackgroundSectionFoot = styled(download)`
  width: 100%;
  background-size: cover;
`

export default StyledBackgroundSectionFoot
